import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { TwitterPicker } from 'react-color';
import { UpDragIcon, DownDragIcon } from '../../assets/icons/iconshere';
import { SearchesCIconData } from '../../SvgIconsData'
import { tagColors, recursiveKeys } from '../../utils/constant';


const emptyData = {
    displayName: "",
    description: "",
    children: [],
    id: 0
}




const CreateRoles = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [viewOnly, setViewOnly] = useState(false);
    const [name, setName] = useState("");
    const [color, setColor] = useState(tagColors[Math.floor(Math.random() * tagColors.length)]);
    const [search1, setSearch1] = useState("");
    const [search2, setSearch2] = useState("");
    const [description, setDescription] = useState("");
    const [data, setData] = useState(emptyData);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [currentScope, setCurrentScope] = useState([]);
    const [expandedLevel2, setexpandedLevel2] = useState([]);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [id, setId] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errorDesc, setErrorDesc] = useState(null);
    const [error, setError] = useState(null);


    const noHtmlTags = /^(?!.*<.*?>).*$/;

    const checkForHtmlTags = (input) => {
       return noHtmlTags.test(input);
    };

    const submit = async () => {
        setErrorName(null);
        setErrorDesc(null);
        setError(null);
        if (!checkForHtmlTags(name)) {
            setErrorName("Invalid role title")
            setTimeout(() => { setErrorName(null) }, 3000)
            return;
          }

        if (!checkForHtmlTags(description)) {
            setErrorDesc("Invalid description")
            setTimeout(() => { setErrorDesc(null) }, 3000)
            console.error("Invalid input: HTML tags are not allowed in description");
            return;
        }


        try {
            if (name.length < 2) {
                setError("Enter a valid name");
                setTimeout(() => setError(""), 2000)
            } else if (!selectedPermissions.length) {
                setError("Role should have atleast 1 permission!");
                setTimeout(() => setError(""), 2000)
            } else {
                if (id) {
                    await axios.post(`${configData.config.UNO_URL}/api/role/update/${id}`, {
                        name,
                        description,
                        permissions: selectedPermissions,
                        color,
                        mode: state.mode
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    });
                } else {
                    await axios.post(`${configData.config.UNO_URL}/api/role/create/${userData.user.group.uuid}`, {
                        name,
                        description,
                        permissions: selectedPermissions,
                        color,
                        mode: state.mode
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    });
                }

                navigate(-1);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchPermissions = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/role/permissions`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setData(data.data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchPermissions()
    }, []);

    const togglePermission = (permission, checked) => {
        const ids = recursiveKeys(permission, 'id');
        if (checked) {
            if (selectedPermissions.includes(permission.parentId));
            setSelectedPermissions([...selectedPermissions.filter(e => !ids.includes(e))]);
        } else {
            setSelectedPermissions([...selectedPermissions.filter(e => !ids.includes(e)), permission.id])
        }
    };
    useEffect(() => {
        if (state && state.type) {
            switch (state.type) {
                case 'view':
                    setViewOnly(true);
                    setName(state.role.name);
                    setDescription(state.role.description);
                    setSelectedPermissions(state.role.permissions.map(e => e.id))
                    if (state.role.color) setColor(state.role.color)
                    break;
                case 'edit':
                    setName(state.role.name);
                    setDescription(state.role.description);
                    setSelectedPermissions(state.role.permissions.map(e => e.id))
                    setId(state.role.id)
                    if (state.role.color) setColor(state.role.color)
                    break;
                case 'copy':
                    // setName(state.role.name);
                    setDescription(String(state.role.description).concat(" - Copy"));
                    setSelectedPermissions(state.role.permissions.map(e => e.id))
                    if (state.role.color) setColor(state.role.color)
                    break;
                default:
            }
        }
    }, [state])
    return (
        <>
            <section className='rolesaccess mt-2 ms-2'>
                <div className="row justify-content-between align-items-center">
                    <div className="col">
                        <div className='pagetitletext'>
                            {viewOnly ? "View" : id ? "Update" : "Create"} Role
                        </div>
                    </div>
                    <div className="col text-end">
                        <button type='button' className='createbtn cancel' onClick={() => navigate(-1)} >Cancel</button>
                        {viewOnly ? null : <button type='button' onClick={() => submit()} className='createbtn ms-3'>{id ? "Update" : "Create"}</button>}
                    </div>
                </div>

                <div className='row mt-4' >
                    <div className='col-md-4'>
                        <div className="searchfields form-group inputfiled" style={{ maxWidth: "100%" }}>
                            <p className='namehere'>Role Title </p>
                            <input disabled={viewOnly} value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter Role Title" className='form-control' />
                            { errorName && <p style={{  color: 'red', fontSize: '12px' }}>{errorName}</p>}
                            { error && <p style={{  color: 'red', fontSize: '12px' }}>{error}</p>}
                        </div>
                        <div className='row colorpicker' >
                            <TwitterPicker color={color} colors={tagColors} onChangeComplete={(e) => !viewOnly ? setColor(e.hex) : null} />
                            <span style={{ backgroundColor: color, width: '45px', height: '45px', borderRadius: '50%' }} />
                        </div>
                        <div className='row rolemanage mt-4'>
                            <div className='col-12 appscroll'>
                                <h5>Manage Permissions</h5>
                                <div className="searchfields form-group mt-3">
                                    <span className='searchinconssd'> <SearchesCIconData /> </span>
                                    <input type="text" value={search1} onChange={(e) => setSearch1(e.target.value)} placeholder='Search' className='form-control' />
                                </div>
                                <div className='permList withbarscroll'>
                                    {data.children.map((level1, index) => {
                                        if (search1.length && !level1.name.toLowerCase().includes(search1.toLowerCase())) return null;
                                        const checked1 = selectedPermissions.includes(level1.id) || selectedPermissions.includes(level1.parentId);
                                        return <div key={level1.id} className='permitem' onClick={() => setCurrentScope(level1)}>
                                            <h3>{level1.displayName}</h3>
                                            <div className="form-check permissionhere">
                                                <p>All Permissions</p>
                                                <label className="form-check-label" htmlFor={`level1CheckBox_${level1.id}`}>
                                                    <input disabled={viewOnly || selectedPermissions.includes(level1.parentId)} checked={checked1} onChange={(() => togglePermission(level1, checked1))} className="form-check-input" type="checkbox" id={`level1CheckBox_${level1.id}`} />
                                                </label>
                                            </div>
                                        </div>
                                    })}

                                     
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className="searchfields form-group inputfiled" style={{ maxWidth: "100%" }}>
                            <p className='namehere'>Description</p>
                            <input disabled={viewOnly} value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder="Enter Description" className='form-control' />
                            { errorDesc && <p style={{  color: 'red', fontSize: '12px' }}>{errorDesc}</p>}
                        </div>
                        <div className='row rolemanage mt-4'>


                            {currentScope.id ? <div className='col-12 appscroll'>
                                <h5>{currentScope.displayName}</h5>
                                <p>{currentScope.description}</p>
                                <div className="searchfields form-group mt-3">
                                    <span className='searchinconssd'> <SearchesCIconData /> </span>
                                    <input type="text" value={search2} onChange={(e) => setSearch2(e.target.value)} placeholder='Search' className='form-control' />
                                </div>
                                <div className='permList withbarscroll permtable'>
                                    <div className="substable">
                                        <div className="row align-items-center align-middle subheadlist align-middle p-1 px-2">
                                            <div className="col-3 p-2 ps-3">Roles</div>
                                            <div className="col-7 p-2">Description</div>
                                            <div className="col-2 p-2 text-center">Enable</div>
                                        </div>
                                        {currentScope.children.map((level2) => {
                                            const names = recursiveKeys(level2, 'name');
                                            if (search2.length && !names.toString().toLowerCase().includes(search2.toLowerCase())) return null;
                                            const checked2 = selectedPermissions.includes(level2.id) || selectedPermissions.includes(level2.parentId) || selectedPermissions.includes(data.id);
                                            const expanded = expandedLevel2 === level2.id;
                                            return <div key={level2.id} className="row align-items-center subchildlist align-middle p-1 px-2">
                                                <div className="col-3 p-2 ps-3 ">{level2.displayName}</div>
                                                <div className="col-7 p-2 d-flex align-items-center ">
                                                    <div className='perm_descrip'>{level2.description}</div>
                                                </div>
                                                <div className="col-2 p-2  text-center rolelistoptions">
                                                    <label className="form-check-label me-4" htmlFor={`level2CheckBox_${level2.id}`}>
                                                        <input disabled={selectedPermissions.includes(level2.parentId) || viewOnly || selectedPermissions.includes(data.id)} checked={checked2} onChange={(() => togglePermission(level2, checked2))} className="form-check-input" type="checkbox" id={`level2CheckBox_${level2.id}`} />
                                                    </label>
                                                    {
                                                        level2.children.length ? (expanded ?
                                                            <span  className='expand-shrink' onClick={() => setexpandedLevel2(null)}>
                                                            <UpDragIcon/>
                                                            </span>
                                                            
                                                            :

                                                            <span  className='expand-shrink' onClick={() => setexpandedLevel2(level2.id)}>
                                                            <DownDragIcon/>
                                                            </span>
                                                           ) : <span className='expand-shrink'/>
                                                        
                                                    }

                                                </div>

                                                <div className={expanded ? 'childtablelist active' : 'childtablelist'}>
                                                    <div className='role_expanded_list rolesList'>
                                                        {level2.children.map((level3) => {
                                                            const names = recursiveKeys(level3, 'name');
                                                            if (search2.length && !names.toString().toLowerCase().includes(search2.toLowerCase())) return null;
                                                            const checked3 = selectedPermissions.includes(level3.id) || checked2;
                                                            return <div key={level3.id} className="row mt-2 align-items-center subchildlist  align-middle">
                                                                <div className="col-3 p-2 ps-3 ">{level3.displayName}</div>
                                                                <div className="col-7 p-2 d-flex align-items-center ">
                                                                    <div className='perm_descrip'>{level3.description}</div>
                                                                </div>
                                                                <div className="col-2 p-2  text-center">
                                                                    <label className="form-check-label me-4" htmlFor={`level3CheckBox_${level3.id}`}>
                                                                        <input disabled={checked2 || selectedPermissions.includes(data.id)} checked={checked3} onChange={(() => togglePermission(level3, checked3))} className="form-check-input" type="checkbox" id={`level3CheckBox_${level3.id}`} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div> : null}


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateRoles
