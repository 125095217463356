import React from 'react'
import { Link } from 'react-router-dom';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Button from '@mui/material/Button';

import { useSelector, useDispatch } from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';
import { saveUserData } from '../../../store/slice/userSlice';
import avatar from '../../../assets/img/avatar.png'
import Logout from './Logout';


const UserSettingComp = () => {
    const userData = useSelector(state => state.userSlice);

    const renderUserAvatar = () => {
        if (userData && userData.token) {
            const userName = userData?.user?.name;

            if (userData?.user?.avatar?.length) {
                <img src={userData?.user?.avatar} alt={userName} title={userName} />
            } else if (userName) {
                const nameParts = userName.split(' ');
                const placeholder = nameParts.length >= 2
                    ? `${nameParts[0][0]}${nameParts[1][0]}`
                    : `${nameParts[0][0]}`;
                return (
                    <div className="avatar-placeholder">{placeholder.toUpperCase()}</div>
                );
            }
        }

        return null;
    };

    return (
        <>
            {(userData && userData?.token) ?
                <>
                    <Dropdown className='userdropdown py-2'>
                        <Dropdown.Toggle>
                            <div className='d-flex align-items-center'>
                                <span className='d-none d-md-block'>{userData?.user?.name}</span>
                                {renderUserAvatar()}
                                {
                                    userData?.user?.avatar?.length ?
                                        <img src={userData?.user?.avatar} alt={userData?.user?.name} title={userData?.user?.name} />
                                        :
                                        ''
                                }
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dropdown-menu-lg-end'>
                            <div className="userprofile">
                                <div className="profileheading">
                                    {
                                        userData?.user?.avatar?.length ?
                                            <img src={userData?.user?.avatar} alt={userData?.user?.name} title={userData?.user?.name} />
                                            :
                                            ''
                                    }
                                    {renderUserAvatar()}
                                    <div>
                                        <h6>{userData?.user?.name}</h6>
                                        <p>{userData?.user?.email || userData?.user?.uid}</p>
                                    </div>
                                </div>
                                
                                <hr />
                                <p className='mb-0'><Logout /></p>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
                : null}
        </>
    )
}

export default UserSettingComp
